import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';

import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const Hooks = ({
  data: {
    allHooks: { nodes },
  },
}) => {
  const sortedHooks = nodes.sort((a, b) => a.frontmatter.title.localeCompare(b.frontmatter.title));

  return (
    <Layout pageTitle="Hooks">
      <Ink.Box bottom="16">
        <Header title="Hooks" subtitle="Hooks enable ink components to encapsulate reusable logic" />
      </Ink.Box>
      <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
        {sortedHooks.map(node => (
          <PreviewCard
            key={node.frontmatter.title}
            title={node.frontmatter.title}
            text={node.frontmatter.subtitle}
            actionLabel="View hook"
            actionHref={`/hooks/${node.slug}`}
            previewWithCheckerboard
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default Hooks;

export const query = graphql`
  query HooksQuery {
    allHooks: allMdx(filter: { fileAbsolutePath: { regex: "/hooks/" } }) {
      nodes {
        frontmatter {
          title
          subtitle
        }
        slug
      }
    }
  }
`;
